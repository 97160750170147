<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <div class="card p-fluid">
        <h5>修改密码</h5>
        <div class="field">
            <label for="originPsdInput">原密码</label>
            <Password id="originPsdInput" toggleMask v-model="userpsdInfo.originpsd" />
        </div>
        <div class="field">
            <label for="newPsdInput">新密码</label>
            <Password id="newPsdInput" toggleMask v-model="userpsdInfo.newpsd" />
        </div>
        <div class="field">
            <Button label="保存" @click="saveInfo" :loading="saveloading" class="p-button-raised" />
        </div>
    </div>
</template>
<script>
    import requests from '../../service/newaxios';
    import MessageTip from '../../components/Message';
    export default {
        data() {
            return {
                saveloading: false,
                userpsdInfo: {
                    userid: sessionStorage.getItem("userid"),
                    originpsd: '',
                    newpsd: '',
                }
            }
        },
        methods: {
            saveInfo() {
                this.saveloading = true;
                if (this.userpsdInfo.userid == '' || this.userpsdInfo.userid == null || this.userpsdInfo.userid ==
                    undefined) {
                    MessageTip.warnmsg('当前用户信息丢失,请重新登陆');
                    return;
                }
                if (this.userpsdInfo.originpsd == '') {
                    MessageTip.warnmsg('原密码不能为空');
                    return;
                }
                if (this.userpsdInfo.newpsd == '') {
                    MessageTip.warnmsg('新密码不能为空');
                    return;
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "mutation{userpsd(o:" + JSON.stringify(this.userpsdInfo) +
                    "){userid}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        MessageTip.successmsg('修改成功');
                    }
                    this.saveloading = false;
                });
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
        }
    }
</script>